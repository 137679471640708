<template>
  <div>
    <div class="right-menu shipping-right" v-loading.fullscreen.lock="loadingSettings">
      
      <subHeader pageName="THEME CUSTOMIZATION" />

      <tutorialsAction />

      <div class="db_top pt-4">
        <div class="db-container">
          
          <div class="main-search-order">
            <div class="row search-order-product d-flex-middle">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-9">
                    <div class="addNewOrderBtn text-left" v-if="isDev()">
                      <button class="mr-2" @click.prevent="openNewPageModal()" :disabled="tourIsActive">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.25 20.25">
                          <g id="plus" transform="translate(-7.875 -7.875)">
                            <path id="Path_39" data-name="Path 39" d="M18,7.875A1.125,1.125,0,0,1,19.125,9v9A1.125,1.125,0,0,1,18,19.125H9a1.125,1.125,0,0,1,0-2.25h7.875V9A1.125,1.125,0,0,1,18,7.875Z" fill="#fbfbfc" fill-rule="evenodd"/>
                            <path id="Path_40" data-name="Path 40" d="M16.875,18A1.125,1.125,0,0,1,18,16.875h9a1.125,1.125,0,0,1,0,2.25H19.125V27a1.125,1.125,0,0,1-2.25,0Z" fill="#fbfbfc" fill-rule="evenodd"/>
                          </g>
                        </svg>
                        ADD NEW PAGE
                      </button>
                      <button class="mr-2" @click.prevent="() => {resetSection();sectionForm.isOpen = true;}" :disabled="tourIsActive">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.25 20.25">
                          <g id="plus" transform="translate(-7.875 -7.875)">
                            <path id="Path_39" data-name="Path 39" d="M18,7.875A1.125,1.125,0,0,1,19.125,9v9A1.125,1.125,0,0,1,18,19.125H9a1.125,1.125,0,0,1,0-2.25h7.875V9A1.125,1.125,0,0,1,18,7.875Z" fill="#fbfbfc" fill-rule="evenodd"/>
                            <path id="Path_40" data-name="Path 40" d="M16.875,18A1.125,1.125,0,0,1,18,16.875h9a1.125,1.125,0,0,1,0,2.25H19.125V27a1.125,1.125,0,0,1-2.25,0Z" fill="#fbfbfc" fill-rule="evenodd"/>
                          </g>
                        </svg>
                        Add new section in ( {{ field }} )
                      </button>
                      <button class="mr-2" @click.prevent="() => {resetForm();fieldForm.isOpen = true;}" :disabled="tourIsActive">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.25 20.25">
                          <g id="plus" transform="translate(-7.875 -7.875)">
                            <path id="Path_39" data-name="Path 39" d="M18,7.875A1.125,1.125,0,0,1,19.125,9v9A1.125,1.125,0,0,1,18,19.125H9a1.125,1.125,0,0,1,0-2.25h7.875V9A1.125,1.125,0,0,1,18,7.875Z" fill="#fbfbfc" fill-rule="evenodd"/>
                            <path id="Path_40" data-name="Path 40" d="M16.875,18A1.125,1.125,0,0,1,18,16.875h9a1.125,1.125,0,0,1,0,2.25H19.125V27a1.125,1.125,0,0,1-2.25,0Z" fill="#fbfbfc" fill-rule="evenodd"/>
                          </g>
                        </svg>
                        ADD NEW Field in ( {{ section }} )
                      </button>
                      <button class="mr-2" @click.prevent="setImageToDefaultHandle()" :disabled="tourIsActive">
                        <svg height="20px" id="Layer_1" style="enable-background:new 0 0 512 512;fill:#fff;" version="1.1" viewBox="0 0 512 512" width="20px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g>
                            <path d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z"/>
                            <path d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z"/>
                          </g>
                        </svg>
                        Set Images to Default
                      </button>
                      <button @click.prevent="downloadBlockImagesHandle()" :disabled="tourIsActive">
                        <svg style="enable-background:new 0 0 24 24;fill:#fff;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g id="info"/>
                          <g id="icons">
                            <g id="save">
                              <path d="M11.2,16.6c0.4,0.5,1.2,0.5,1.6,0l6-6.3C19.3,9.8,18.8,9,18,9h-4c0,0,0.2-4.6,0-7c-0.1-1.1-0.9-2-2-2c-1.1,0-1.9,0.9-2,2    c-0.2,2.3,0,7,0,7H6c-0.8,0-1.3,0.8-0.8,1.4L11.2,16.6z"/>
                              <path d="M19,19H5c-1.1,0-2,0.9-2,2v0c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1v0C21,19.9,20.1,19,19,19z"/>
                            </g>
                          </g>
                        </svg>
                        Download Block Images
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="addNewOrderBtn text-right">
                      <button @click.prevent="$router.push('/theme-management/theme-banner')" id="edit-theme-btn" :disabled="tourIsActive">Edit Theme Banners</button>
                      <button @click.prevent="saveBlocks" id="save-btn" :disabled="tourIsActive">SAVE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div class="tabsMainSection">

            <!-- tabs button list block starts here -->
            <div class="tabsListSection">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation" v-for="(group, groupKey) in blocks" :key="group.id">
                  <button 
                    class="nav-link" 
                    :class="acitveTab == groupKey ? 'active' : '' " 
                    @click="selectTab(groupKey)" 
                    :id="`${group.id}`" 
                    data-toggle="pill" 
                    :data-target="`#${groupKey}`" 
                    type="button" 
                    role="tab" 
                    :aria-selected="acitveTab == group.name ? 'true' : '' " 
                    :aria-controls="`#${groupKey}`"
                    :disabled="tourIsActive"
                  >
                    {{ group.name }}
                  </button>
                  <a href="#." :id="`page-options-${group.id}`" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" v-if="isDev()">
                    <span class="arrowIconbox">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.724" height="5.362" viewBox="0 0 10.724 5.362">
                        <path id="dropDownArrow" d="M1873,51l5,5,5-5Z" transform="translate(-1872.638 -50.85)" stroke="#000" stroke-width="0.3"/>
                      </svg>
                    </span>
                  </a>
                  <div class="dropdown-menu" :aria-labelledby="`page-options-${group.id}`" v-if="isDev()">
                    <button class="dropdown-item" @click.prevent="openEditPageModal(group)">
                      Edit Page
                    </button>
                    <button class="dropdown-item" @click.prevent="removePageHandle(group)">
                      Delete Page
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tabsListSection sectionItems">
              <ul class="nav nav-pills" id="pills-section-tab" role="tablist" v-if="acitveTab">
                <li class="nav-item" role="presentation">
                  <button 
                    class="nav-link"
                    :class="activeSection == 'non-grouped-fields' ? 'active' : '' "
                    @click="selectSectionTab('non-grouped-fields')" 
                  >
                    Non Grouped Fields
                  </button>
                </li>
                <li class="nav-item" role="presentation" v-for="(section, sectionKey) in blocks[acitveTab].sections" :key="section.id">
                  <button 
                    class="nav-link" 
                    :class="activeSection == sectionKey ? 'active' : '' " 
                    @click="selectSectionTab(sectionKey)" 
                    :id="`${section.id}`" 
                    data-toggle="pill" 
                    :data-target="`#${sectionKey}`" 
                    type="button" 
                    role="tab" 
                    :aria-selected="activeSection == section.name ? 'true' : '' " 
                    :aria-controls="`#${sectionKey}`"
                    :disabled="tourIsActive"
                  >
                    {{ section.name }}
                  </button>
                  <a href="#.">
                    <span class="arrowIconbox" @click.prevent="hideShowSectionHandle(sectionKey, section)">
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="width: 20px;" v-if="!section.active">
                        <g>
                          <path d="M0 0h24v24H0z" fill="none"/>
                          <path d="M9.342 18.782l-1.931-.518.787-2.939a10.988 10.988 0 0 1-3.237-1.872l-2.153 2.154-1.415-1.415 2.154-2.153a10.957 10.957 0 0 1-2.371-5.07l1.968-.359C3.903 10.812 7.579 14 12 14c4.42 0 8.097-3.188 8.856-7.39l1.968.358a10.957 10.957 0 0 1-2.37 5.071l2.153 2.153-1.415 1.415-2.153-2.154a10.988 10.988 0 0 1-3.237 1.872l.787 2.94-1.931.517-.788-2.94a11.072 11.072 0 0 1-3.74 0l-.788 2.94z"/>
                        </g>
                      </svg>
                      <svg 
                        enable-background="new 0 0 32 32" 
                        height="25px" 
                        id="Layer_1" 
                        version="1.1" 
                        viewBox="0 0 32 32" 
                        width="25px" 
                        xml:space="preserve" 
                        xmlns="http://www.w3.org/2000/svg" 
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        v-else
                      >
                        <g>
                          <polyline fill="none" points="   649,137.999 675,137.999 675,155.999 661,155.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                          <polyline fill="none" points="   653,155.999 649,155.999 649,141.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                          <polyline fill="none" points="   661,156 653,162 653,156  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                        </g>
                        <g>
                          <g>
                            <path d="M16,25c-4.265,0-8.301-1.807-11.367-5.088c-0.377-0.403-0.355-1.036,0.048-1.413c0.404-0.377,1.036-0.355,1.414,0.048    C8.778,21.419,12.295,23,16,23c4.763,0,9.149-2.605,11.84-7c-2.69-4.395-7.077-7-11.84-7c-4.938,0-9.472,2.801-12.13,7.493    c-0.272,0.481-0.884,0.651-1.363,0.377c-0.481-0.272-0.649-0.882-0.377-1.363C5.147,10.18,10.333,7,16,7    c5.668,0,10.853,3.18,13.87,8.507c0.173,0.306,0.173,0.68,0,0.985C26.853,21.819,21.668,25,16,25z"/>
                          </g>
                          <g>
                            <path d="M16,21c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S18.757,21,16,21z M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3    s3-1.346,3-3S17.654,13,16,13z"/>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </a>
                  <a href="#." :id="`page-options-${section.id}`" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" v-if="isDev()">
                    <span class="arrowIconbox">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.724" height="5.362" viewBox="0 0 10.724 5.362">
                        <path id="dropDownArrow" d="M1873,51l5,5,5-5Z" transform="translate(-1872.638 -50.85)" stroke="#000" stroke-width="0.3"/>
                      </svg>
                    </span>
                  </a>
                  <div class="dropdown-menu" :aria-labelledby="`page-options-${section.id}`" v-if="isDev()">
                    <button class="dropdown-item" @click.prevent="openEditSectionModal(section)">
                      Edit Section
                    </button>
                    <button class="dropdown-item" @click.prevent="removeSectionModal(section)">
                      Delete Section
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <!-- tabs button list block ends here -->

            <!-- tabs content section block starts here -->
            <div class="tabsContentSection">
              <div class="tabsContent" id="content-blocks-section">
                <div class="tab-content" id="pills-tabContent" v-if="activeSection && activeSection == 'non-grouped-fields'">
                  <div 
                    class="tab-pane fade" 
                    :class="acitveTab == groupKey ? 'active show' : '' " 
                    :id="groupKey" 
                    role="tabpanel" 
                    :aria-labelledby="group.id" 
                    v-for="(group,groupKey) in blocks" 
                    :key="group.id"
                  >
                    <div class="tabMainBox" v-if="isDev()">
                      <div 
                        class="tabCardBox" 
                        :id="`content-card${field.id}`" 
                        v-for="(field, fieldKey) in group.fields" 
                        :key="field.id" 
                        @click="blockSelection(groupKey, field)"
                      >
                        <a href="#." class="ctmCloseBtn" :id="`page-options-${field.id}`" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" v-if="isDev()">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.724" height="5.362" viewBox="0 0 10.724 5.362">
                              <path id="dropDownArrow" d="M1873,51l5,5,5-5Z" transform="translate(-1872.638 -50.85)" stroke="#000" stroke-width="0.3"/>
                            </svg>
                          </span>
                        </a>
                        <div class="dropdown-menu" :aria-labelledby="`page-options-${field.id}`" v-if="isDev()">
                          <button class="dropdown-item" @click.prevent="moveFieldModalOperations('open', fieldKey, field)">
                            Move Field
                          </button>
                          <button class="dropdown-item" @click.prevent="handleDeleteField(groupKey, fieldKey)">
                            Remove Field
                          </button>
                        </div>
                        <div class="fieldBox">
                          <label for="idField">ID</label>
                          <input type="text" name="idField" id="idField" v-model="field.id" disabled />
                        </div>
                        <div class="fieldBox">
                          <label for="nameField">Name</label>
                          <input type="text" name="nameField" id="nameField" v-model="field.name" disabled />
                        </div>
                        <div class="fieldBox">
                          <label for="slugField">Slug</label>
                          <input type="text" name="slugField" id="slugField" :value="fieldKey" disabled />
                        </div>
                        <div class="fieldBox">
                          <label for="typeField">Type</label>
                          <input type="text" name="typeField" id="typeField" v-model="field.type" disabled />
                        </div>
                        <template v-if="field.type == 'text'">
                          <div class="fieldBox">
                            <label for="valueField">Value</label>
                            <input type="text" name="valueField" id="valueField" placeholder="Value" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="colorField">Color</label>
                            <input type="color" name="colorField" id="colorField" placeholder="Color" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="id">About ( {{ field.name }} ) 
                              <el-tooltip placement="top">
                                <div slot="content">Could you provide a description of this text field?<br/> I can generate content based on the information you provide.</div>
                                <el-button style="padding: 0" icon="el-icon-info" circle></el-button>
                              </el-tooltip>
                            </label>
                            <textarea name="about" v-model="field.about" id="" cols="30" rows="10" :disabled="tourIsActive"></textarea>
                          </div>
                        </template>
                        <template v-else-if="field.type == 'image'">
                          <div class="fieldBox">
                            <label for="altField">Alt</label>
                            <input type="text" name="altField" id="altField" placeholder="Alt" v-model="field.alt" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkField">Link</label>
                            <input type="text" name="linkField" id="linkField" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="id">Image</label>
                            <div class="fieldImage" v-if="field.default">
                              <img 
                                width="100%" 
                                :id="`blockDefaultImage-${field.id}`" :src=" spaces + 'theme/' + (settings.theme && settings.theme.id) + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event, groupKey, fieldKey, field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                              <div class="downloaderNewImg" @click="imageDownloadHandle(`blockDefaultImage-${field.id}`,field,$event)">
                                <svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <g id="info"/>
                                  <g id="icons">
                                    <g id="save">
                                      <path d="M11.2,16.6c0.4,0.5,1.2,0.5,1.6,0l6-6.3C19.3,9.8,18.8,9,18,9h-4c0,0,0.2-4.6,0-7c-0.1-1.1-0.9-2-2-2c-1.1,0-1.9,0.9-2,2    c-0.2,2.3,0,7,0,7H6c-0.8,0-1.3,0.8-0.8,1.4L11.2,16.6z"/>
                                      <path d="M19,19H5c-1.1,0-2,0.9-2,2v0c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1v0C21,19.9,20.1,19,19,19z"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                            <div class="fieldImage" v-else>
                              <img 
                                width="100%" 
                                :id="`blockImage-${field.id}`" :src="base_image_url + settings.store_id + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event, groupKey, fieldKey, field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                              <div class="downloaderNewImg" @click="imageDownloadHandle(`blockImage-${field.id}`,field,$event)">
                                <svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <g id="info"/>
                                  <g id="icons">
                                    <g id="save">
                                      <path d="M11.2,16.6c0.4,0.5,1.2,0.5,1.6,0l6-6.3C19.3,9.8,18.8,9,18,9h-4c0,0,0.2-4.6,0-7c-0.1-1.1-0.9-2-2-2c-1.1,0-1.9,0.9-2,2    c-0.2,2.3,0,7,0,7H6c-0.8,0-1.3,0.8-0.8,1.4L11.2,16.6z"/>
                                      <path d="M19,19H5c-1.1,0-2,0.9-2,2v0c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1v0C21,19.9,20.1,19,19,19z"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="field.type == 'link'">
                          <div class="fieldBox">
                            <label for="textField">Text</label>
                            <input type="text" name="textField" id="textField" placeholder="Text" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkField">Link</label>
                            <input type="text" name="linkField" id="linkField" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="newTab">New Tab</label>
                            <input type="checkbox" name="newTab" id="newTab" v-model="field.newTab" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="textColor">Text Color</label>
                            <input type="color" name="textColor" id="textColor" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="tabMainBox" v-else>
                      <div 
                        class="tabCardBox" 
                        :id="`content-card${field.id}`" 
                        v-for="(field, fieldKey) in group.fields" 
                        :key="field.id" 
                        @click="blockSelection(groupKey, field)"
                      >
                        <template v-if="field.type == 'text' ">
                          <div class="fieldBox">
                            <label for="valueField">Value</label>
                            <input type="text" name="valueField" id="valueField" placeholder="Value" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="colorField">Color</label>
                            <input type="color" name="colorField" id="colorField" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                        </template>
                        <template v-else-if="field.type == 'image' ">
                          <div class="fieldBox">
                            <label for="altField">Alt</label>
                            <input type="text" name="altField" id="altField" placeholder="Alt" v-model="field.alt" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkField">Link</label>
                            <input type="text" name="linkField" id="linkField" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="id">Image</label>
                            <div class="fieldImage" v-if="field.default">
                              <img 
                                width="100%" 
                                :src=" spaces + 'theme/' + (settings.theme && settings.theme.id) + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event,groupKey,fieldKey,field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                            </div>
                            <div class="fieldImage" v-else>
                              <img 
                                width="100%" 
                                :src="base_image_url +settings.store_id + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event,groupKey,fieldKey,field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="field.type == 'link'">
                          <div class="fieldBox">
                            <label for="textField">Text</label>
                            <input type="text" name="textField" id="textField" placeholder="Text" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkField">Link</label>
                            <input type="text" name="linkField" id="linkField" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="newTab">New Tab</label>
                            <input type="checkbox" name="newTab" id="newTab" v-model="field.newTab" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="textColor">Text Color</label>
                            <input type="color" name="textColor" id="textColor" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-content" id="pills-tabContent" v-else-if="activeSection && activeSection != 'non-grouped-fields'">  
                  <div 
                    class="tab-pane fade" 
                    :class="activeSection == sectionKey ? 'active show' : '' " 
                    :id="sectionKey" 
                    role="tabpanel" 
                    :aria-labelledby="section.id" 
                    v-for="(section, sectionKey) in blocks[acitveTab]['sections']" 
                    :key="section.id"
                  >
                    <div class="tabMainBox" v-if="isDev()">
                      <div 
                        class="tabCardBox" 
                        :id="`content-card${field.id}`" 
                        v-for="(field, fieldKey) in section.fields" 
                        :key="field.id" 
                        @click="blockSelection(sectionKey, field)"
                      >
                        <a href="#." class="ctmCloseBtn" :id="`page-options-${field.id}`" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" v-if="isDev()">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.724" height="5.362" viewBox="0 0 10.724 5.362">
                              <path id="dropDownArrow" d="M1873,51l5,5,5-5Z" transform="translate(-1872.638 -50.85)" stroke="#000" stroke-width="0.3"/>
                            </svg>
                          </span>
                        </a>
                        <div class="dropdown-menu" :aria-labelledby="`page-options-${field.id}`" v-if="isDev()">
                          <button class="dropdown-item" @click.prevent="moveFieldModalOperations('open', fieldKey, field)">
                            Move Field
                          </button>
                          <button class="dropdown-item" @click.prevent="handleDeleteField(sectionKey, fieldKey)">
                            Remove Field
                          </button>
                        </div>

                        <div class="fieldBox">
                          <label for="idField">ID</label>
                          <input type="text" name="idField" id="idField" v-model="field.id" disabled />
                        </div>
                        <div class="fieldBox">
                          <label for="nameField">Name</label>
                          <input type="text" name="nameField" id="nameField" v-model="field.name" disabled />
                        </div>
                        <div class="fieldBox">
                          <label for="slugField">Slug</label>
                          <input type="text" name="slugField" id="slugField" :value="fieldKey" disabled />
                        </div>
                        <div class="fieldBox">
                          <label for="typeField">Type</label>
                          <input type="text" name="typeField" id="typeField" v-model="field.type" disabled />
                        </div>
                        <template v-if="field.type == 'text'">
                          <div class="fieldBox">
                            <label for="valueField">Value</label>
                            <input type="text" name="valueField" id="valueField" placeholder="Value" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="colorField">Color</label>
                            <input type="color" name="colorField" id="colorField" placeholder="Color" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="aboutField">About ( {{ field.name }} ) 
                              <el-tooltip placement="top">
                                <div slot="content">Could you provide a description of this text field?<br/> I can generate content based on the information you provide.</div>
                                <el-button style="padding: 0" icon="el-icon-info" circle></el-button>
                              </el-tooltip>
                            </label>
                            <textarea name="about" v-model="field.about" id="aboutField" cols="30" rows="10" :disabled="tourIsActive"></textarea>
                          </div>
                        </template>
                        <template v-else-if="field.type == 'image'">
                          <div class="fieldBox">
                            <label for="altField">Alt</label>
                            <input type="text" name="altField" id="altField" placeholder="Alt" v-model="field.alt" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkField">Link</label>
                            <input type="text" name="linkField" id="linkField" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="id">Image {{field.default}}</label>
                            <div class="fieldImage" v-if="field.default">
                              <img 
                                width="100%" 
                                :id="`blockDefaultImage-${field.id}`" :src=" spaces + 'theme/' + (settings.theme && settings.theme.id) + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event, sectionKey, fieldKey, field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                              <div class="downloaderNewImg" @click="imageDownloadHandle(`blockDefaultImage-${field.id}`,field,$event)">
                                <svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <g id="info"/>
                                  <g id="icons">
                                    <g id="save">
                                      <path d="M11.2,16.6c0.4,0.5,1.2,0.5,1.6,0l6-6.3C19.3,9.8,18.8,9,18,9h-4c0,0,0.2-4.6,0-7c-0.1-1.1-0.9-2-2-2c-1.1,0-1.9,0.9-2,2    c-0.2,2.3,0,7,0,7H6c-0.8,0-1.3,0.8-0.8,1.4L11.2,16.6z"/>
                                      <path d="M19,19H5c-1.1,0-2,0.9-2,2v0c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1v0C21,19.9,20.1,19,19,19z"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                            <div class="fieldImage" v-else>
                              <img 
                                width="100%" 
                                :id="`blockImage-${field.id}`" :src="base_image_url + settings.store_id + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event, sectionKey, fieldKey, field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                />
                              </div>
                              <div class="downloaderNewImg" @click="imageDownloadHandle(`blockImage-${field.id}`,field,$event)">
                                <svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <g id="info"/>
                                  <g id="icons">
                                    <g id="save">
                                      <path d="M11.2,16.6c0.4,0.5,1.2,0.5,1.6,0l6-6.3C19.3,9.8,18.8,9,18,9h-4c0,0,0.2-4.6,0-7c-0.1-1.1-0.9-2-2-2c-1.1,0-1.9,0.9-2,2    c-0.2,2.3,0,7,0,7H6c-0.8,0-1.3,0.8-0.8,1.4L11.2,16.6z"/>
                                      <path d="M19,19H5c-1.1,0-2,0.9-2,2v0c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1v0C21,19.9,20.1,19,19,19z"/>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="field.type == 'link'">
                          <div class="fieldBox">
                            <label for="textField">Text</label>
                            <input type="text" name="textField" id="textField" placeholder="Text" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkField">Link</label>
                            <input type="text" name="linkField" id="linkField" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="newTab">New Tab</label>
                            <input type="checkbox" name="newTab" id="newTab" v-model="field.newTab" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="textColor">Text Color</label>
                            <input type="color" name="textColor" id="textColor" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="tabMainBox" v-else>
                      <div 
                        class="tabCardBox" 
                        :id="`content-card${field.id}`" 
                        v-for="(field, fieldKey) in section.fields" 
                        :key="field.id" 
                        @click="blockSelection(sectionKey, field)"
                      >
                        <template v-if="field.type == 'text'">
                          <div class="fieldBox">
                            <label for="value">Value</label>
                            <input type="text" name="value" id="value" placeholder="Value" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="color">Color</label>
                            <input type="color" name="color" id="color" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                        </template>
                        <template v-else-if="field.type == 'image'">
                          <div class="fieldBox">
                            <label for="altField">Alt</label>
                            <input type="text" name="altField" id="altField" placeholder="Alt" v-model="field.alt" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkField">Link</label>
                            <input type="text" name="linkField" id="linkField" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="id">Image</label>
                            <div class="fieldImage" v-if="field.default">
                              <img 
                                width="100%" 
                                :src=" spaces + 'theme/' + (settings.theme && settings.theme.id) + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event,sectionKey,fieldKey,field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                            </div>
                            <div class="fieldImage" v-else>
                              <img 
                                width="100%" 
                                :src="base_image_url +settings.store_id + '/content-block/' + field.value" 
                                alt=""
                              />
                              <div class="uploaderNewImg">
                                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                                  <path d="M0 0h48v48h-48z" fill="none" />
                                </svg>
                                <input
                                  accept="image/png, image/jpeg, image/webp"
                                  type="file"
                                  @change="updateImageHandler($event,sectionKey,fieldKey,field.value)"
                                  name="newImgUpload"
                                  id="newImgUpload"
                                  :disabled="tourIsActive"
                                />
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="field.type == 'link'">
                          <div class="fieldBox">
                            <label for="textField">Text</label>
                            <input type="text" name="textField" id="textField" placeholder="Text" v-model="field.value" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="linkField">Link</label>
                            <input type="text" name="linkField" id="linkField" placeholder="Link" v-model="field.link" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="newTab">New Tab</label>
                            <input type="checkbox" name="newTab" id="newTab" v-model="field.newTab" :disabled="tourIsActive" />
                          </div>
                          <div class="fieldBox">
                            <label for="textColor">Text Color</label>
                            <input type="color" name="textColor" id="textColor" v-model="field.color" :disabled="tourIsActive" />
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tabPageSection">
                <div class="siteBox">
                  <div class="siteViewBox" id="siteView">
                    <iframe ref="siteView" :src="siteViewUrl" frameborder="0"></iframe>
                  </div>
                </div>
              </div>
            </div>
            <!-- tabs content section block ends here -->

          </div>

        </div>
      </div>
    </div>

    <!-- add new page modal starts here -->
    <div class="modalBg" :class="form.isOpen ? 'modal-active' : '' ">
      <div class="modalLayers" @click.prevent="form.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;">
          <ValidationObserver ref="addPageObserver">
            <form @submit.prevent="validate('addPage')">
              <div id="London" class="tabcontent">
                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                  <div class="createBlogContainer">
                    <div class="sideTableTitle addNewProductTitle">
                      <h2>ADD NEW PAGE</h2>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <ValidationProvider name="page" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="pageSelection">Page <span>*</span></label>
                            <select class="active1" name="pageSelection" id="pageSelection" v-model="form.selectedPage">
                              <option :value="page" v-for="(page,index) in pagesList" :key="index">
                                {{ page.label == "Home" ? `${page.label} - (${page.slug})` : `${page.label} - (/${page.slug})`}}
                              </option>
                            </select>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-12">
                        <div class="assign-btn">
                          <button type="submit" class="product-btn">
                            ADD PAGE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="form.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- add new page modal ends here -->

    <!-- update page modal starts here -->
    <div class="modalBg" :class="editPageForm.isOpen ? 'modal-active' : '' ">
      <div class="modalLayers" @click.prevent="editPageForm.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;">
          <ValidationObserver ref="editPageObserver">
            <form @submit.prevent="validate('updatePage')">
              <div id="London" class="tabcontent">
                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                  <div class="createBlogContainer">
                    <div class="sideTableTitle addNewProductTitle">
                      <h2>UPDATE PAGE</h2>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <ValidationProvider name="page" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="pageSelection">Page <span>*</span></label>
                            <select class="active1" name="pageSelection" id="pageSelection" v-model="editPageForm.selectedPage">
                              <option :value="page" v-for="(page,index) in pagesList" :key="index">
                                {{ page.label == "Home" ? `${page.label} - (${page.slug})` : `${page.label} - (/${page.slug})`}}
                              </option>
                            </select>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-12">
                        <div class="assign-btn">
                          <button type="submit" class="product-btn">
                            UPDATE PAGE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="editPageForm.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- update page modal starts here -->

    <!-- add new section modal starts here -->
    <div class="modalBg" :class="sectionForm.isOpen ? 'modal-active' : '' ">
      <div class="modalLayers" @click.prevent="sectionForm.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;">
          <ValidationObserver ref="addSectionObserver">
            <form @submit.prevent="validate('addSection')">
              <div id="London" class="tabcontent">
                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                  <div class="createBlogContainer">
                    <div class="sideTableTitle addNewProductTitle">
                      <h2>ADD NEW SECTION IN ( {{ field }} ) PAGE</h2>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <ValidationProvider name="label" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="sectionLabel">Label <span>*</span></label>
                            <input
                              name="sectionLabel"
                              id="sectionLabel"
                              v-model="sectionForm.label"
                              autocomplete="off"
                              placeholder="Label"
                              @input="slugHandle('addSection')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="slug">Slug <span>*</span></label>
                            <input
                              name="slug"
                              id="slug"
                              placeholder="Slug"
                              v-model="sectionForm.slug"
                              @input="slugHandleSlugField('addSection')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-12">
                        <div class="updateProductBtnBox categorySideBar">
                          <div class="updateCtmCheckBox">
                            <p>Status</p>
                            <div class="productStatusBox">
                              <p>HIDDEN</p>
                              <div class="actionSwitch">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  id="add-product-switch"
                                  v-model="sectionForm.isActive"
                                />
                                <span class="slider round"></span>
                              </label>
                              </div>
                              <p>VISIBLE</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="assign-btn">
                          <loaderBtn class="product-btn" v-if="sectionForm.pending"/>
                          <button type="submit" v-else class="product-btn">
                            Save new section
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="sectionForm.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- add new section modal ends here -->

    <!-- update section modal starts here -->
    <div class="modalBg" :class="updateSectionForm.isOpen ? 'modal-active' : '' ">
      <div class="modalLayers" @click.prevent="updateSectionForm.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;">
          <ValidationObserver ref="updateSectionObserver">
            <form @submit.prevent="validate('updateSection')">
              <div id="London" class="tabcontent">
                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                  <div class="createBlogContainer">
                    <div class="sideTableTitle addNewProductTitle">
                      <h2>UPDATE ( {{ updateSectionForm.record ? updateSectionForm.record.name : '--' }} ) SECTION IN ( {{ field }} ) PAGE</h2>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <ValidationProvider name="label" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="sectionLabel">Label <span>*</span></label>
                            <input
                              name="sectionLabel"
                              id="sectionLabel"
                              v-model="updateSectionForm.label"
                              autocomplete="off"
                              placeholder="Label"
                              @input="slugHandle('updateSection')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="slug">Slug <span>*</span></label>
                            <input
                              name="slug"
                              id="slug"
                              placeholder="Slug"
                              v-model="updateSectionForm.slug"
                              @input="slugHandleSlugField('updateSection')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-12">
                        <div class="updateProductBtnBox categorySideBar">
                          <div class="updateCtmCheckBox">
                            <p>Status</p>
                            <div class="productStatusBox">
                              <p>HIDDEN</p>
                              <div class="actionSwitch">
                                <label class="switch">
                                  <input
                                    type="checkbox"
                                    id="add-product-switch"
                                    v-model="updateSectionForm.isActive"
                                  />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                              <p>VISIBLE</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="assign-btn">
                          <loaderBtn class="product-btn" v-if="sectionForm.pending"/>
                          <button type="submit" v-else class="product-btn">
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="updateSectionForm.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- update section modal starts here -->

    <!-- move field section modal starts here -->
    <div class="modalBg" :class="moveFieldModal.isOpen ? 'modal-active' : '' ">
      <div class="modalLayers" @click.prevent="moveFieldModal.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;">
          <ValidationObserver ref="moveFieldObserver">
            <form @submit.prevent="validate('moveField')">
              <div id="London" class="tabcontent">
                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                  <div class="createBlogContainer">
                    <div class="sideTableTitle addNewProductTitle">
                      <h2>Move ( {{ moveFieldModal.selectedField ? moveFieldModal.selectedField.name : "--" }} ) Field</h2>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <ValidationProvider name="section" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="slug">Section <span>*</span></label>
                            <select v-model="moveFieldModal.newFieldSection">
                              <option 
                                :value="section"
                                v-for="(section, index) in moveFieldModal.sectionsList"
                                :key="index"
                              >
                                {{ section.name }}
                              </option>
                            </select>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-12">
                        <div class="assign-btn">
                          <loaderBtn class="product-btn" v-if="moveFieldModal.pending"/>
                          <button type="submit" class="product-btn" v-else>
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="moveFieldModal.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- move field section modal ends here -->

    <!-- add new field modal starts here -->
    <div class="modalBg" :class="fieldForm.isOpen ? 'modal-active' : '' ">
      <div class="modalLayers" @click.prevent="fieldForm.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;">
          <ValidationObserver ref="addFieldObserver">
            <form @submit.prevent="validate('addField')">
              <div id="London" class="tabcontent">
                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                  <div class="createBlogContainer">
                    <div class="sideTableTitle addNewProductTitle">
                      <h2>ADD NEW FIELD IN ( {{ section }} )</h2>
                    </div>
                    <div class="row">
                      
                      <div class="col-md-6">
                        <ValidationProvider name="label" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="contentName">Label <span>*</span></label>
                            <input
                              name="contentName"
                              id="contentName"
                              v-model="fieldForm.label"
                              autocomplete="off"
                              placeholder="Label"
                              @input="slugHandle('addField')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="newFieldSlug">Slug <span>*</span></label>
                            <input
                              name="newFieldSlug"
                              id="newFieldSlug"
                              placeholder="Slug"
                              v-model="fieldForm.slug"
                              @input="slugHandleSlugField('addField')"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                          <div class="formContentBox">
                            <label for="slug">Type <span>*</span></label>
                            <select v-model="fieldForm.type">
                              <option value="text">Text</option>
                              <option value="image">Image</option>
                              <option value="link">Link</option>
                            </select>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>

                      <!-- if field type is 'text' starts here -->

                        <div class="col-md-6" v-if="fieldForm.type == 'text'">
                          <ValidationProvider name="value" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="text-field-value">Value <span>*</span></label>
                              <input
                                name="text-field-value"
                                id="text-field-value"
                                placeholder="Value"
                                v-model="fieldForm.value"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'text'">
                          <ValidationProvider name="color" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="">Color <span>*</span></label>
                              <input
                                type="color"
                                name="text-color"
                                id="text-color"
                                v-model="fieldForm.color"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'text'">
                          <div class="formContentBox">
                            <label for="slug">About ({{ fieldForm.label }})</label>
                            <textarea placeholder="About" name="about" v-model="fieldForm.about" cols="30" rows="10"></textarea>
                          </div>
                        </div>

                      <!-- if field type is 'text' ends here -->

                      <!-- if field type is 'image' starts here -->

                        <div class="col-md-6" v-if="fieldForm.type == 'image'">
                          <ValidationProvider name="image" ref="imageProvider" rules="required|image|mimes:jpg,png,jpeg,webp" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="">Image <span>*</span></label>
                              <input
                                type="file"
                                accept="image/png, image/jpeg, image/webp"
                                name="imageselector"
                                id="imageselector"
                                ref="fieldImageUploader"
                                @change="handleImageAddField"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'image'">
                          <ValidationProvider name="alt" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="imageAlt">Alt <span>*</span></label>
                              <input
                                name="imageAlt"
                                id="imageAlt"
                                placeholder="Alt"
                                v-model="fieldForm.alt"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'image'">
                          <div class="formContentBox">
                            <label for="imageLink">link <span></span></label>
                            <input
                              name="imageLink"
                              id="imageLink"
                              placeholder="Link"
                              v-model="fieldForm.link"
                            />
                          </div>
                        </div>

                      <!-- if field type is 'image' ends here -->

                      <!-- if field type is 'link' starts here -->

                        <div class="col-md-6" v-if="fieldForm.type == 'link'">
                          <ValidationProvider name="text" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="linkText">Text <span>*</span></label>
                              <input
                                name="linkText"
                                id="linkText"
                                placeholder="Text"
                                v-model="fieldForm.value"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'link'">
                          <ValidationProvider name="link" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="linkUrl">Link <span>*</span></label>
                              <input
                                name="linkUrl"
                                id="linkUrl"
                                placeholder="Link"
                                v-model="fieldForm.link"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'link'">
                          <div class="formContentBox">
                            <label for="">New Tab</label>
                            <input
                              type="checkbox"
                              name="newTabOption"
                              id="newTabOption"
                              v-model="fieldForm.newTab"
                            />
                          </div>
                        </div>
                        <div class="col-md-6" v-if="fieldForm.type == 'link'">
                          <ValidationProvider name="text color" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="">Text Color <span>*</span></label>
                              <input
                                type="color"
                                name="textColorField"
                                id="textColorField"
                                v-model="fieldForm.color"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>

                      <!-- if field type is 'link' ends here -->

                      <!-- <template v-if="fieldForm.type == 'text'">
                        <div class="col-md-6">
                          <ValidationProvider name="value" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="text-field-value">Value <span>*</span></label>
                              <input
                                name="text-field-value"
                                id="text-field-value"
                                placeholder="Value"
                                v-model="fieldForm.value"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                          <ValidationProvider name="color" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="">Color <span>*</span></label>
                              <input
                                type="color"
                                name="text-color"
                                id="text-color"
                                v-model="fieldForm.color"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                          <div class="formContentBox">
                            <label for="slug">About ({{ fieldForm.label }})</label>
                            <textarea placeholder="About" name="about" v-model="fieldForm.about" cols="30" rows="10"></textarea>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="fieldForm.type == 'image'">
                        <div class="col-md-6">
                          <ValidationProvider name="image" ref="imageProvider" rules="required|image|mimes:jpg,png,jpeg,webp" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="">Image <span>*</span></label>
                              <input
                                type="file"
                                accept="image/png, image/jpeg, image/webp"
                                name="imageselector"
                                id="imageselector"
                                ref="fieldImageUploader"
                                @change="handleImageAddField"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                          <ValidationProvider name="alt" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="imageAlt">Alt <span>*</span></label>
                              <input
                                name="imageAlt"
                                id="imageAlt"
                                placeholder="Alt"
                                v-model="fieldForm.alt"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                          <div class="formContentBox">
                            <label for="imageLink">link <span></span></label>
                            <input
                              name="imageLink"
                              id="imageLink"
                              placeholder="Link"
                              v-model="fieldForm.link"
                            />
                          </div>
                        </div>
                      </template>
                      <template v-else-if="fieldForm.type == 'link'">
                        <div class="col-md-6">
                          <ValidationProvider name="text" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="linkText">Text <span>*</span></label>
                              <input
                                name="linkText"
                                id="linkText"
                                placeholder="Text"
                                v-model="fieldForm.value"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                          <ValidationProvider name="link" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="linkUrl">Link <span>*</span></label>
                              <input
                                name="linkUrl"
                                id="linkUrl"
                                placeholder="Link"
                                v-model="fieldForm.link"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                          <div class="formContentBox">
                            <label for="">New Tab</label>
                            <input
                              type="checkbox"
                              name="newTabOption"
                              id="newTabOption"
                              v-model="fieldForm.newTab"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <ValidationProvider name="text color" rules="required" v-slot="{ errors }">
                            <div class="formContentBox">
                              <label for="">Text Color <span>*</span></label>
                              <input
                                type="color"
                                name="textColorField"
                                id="textColorField"
                                v-model="fieldForm.color"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                      </template> -->

                      <div class="col-md-12">
                        <div class="assign-btn">
                          <loaderBtn class="product-btn" v-if="fieldForm.isLoading"/>
                          <button type="submit" v-else class="product-btn">
                            SAVE NEW FIELD
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="fieldForm.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- add new field modal ends here -->

    <v-tour name="contentBlocksTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import _ from "lodash";
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  metaInfo() {
    return {
      title: 'Theme Management | Theme Customization | YeetCommerce',
    };
  },
  data() {
    return {
      base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
      spaces: process.env.VUE_APP_SPACES,
      acitveTab: "",
      tempActiveTab: "",
      blocks: {},
      form: {
        selectedPage: null,
        label: "",
        slug: "",
        isOpen: false,
      },
      editPageForm:{
        selectedPage: null,
        tempSelectedPage: null,
        pageId: null,
        isOpen: false,
      },
      fieldForm: {
        isOpen: false,
        label: "",
        slug: "",
        type: "text",
        value: "",
        alt: "",
        link: "",
        isLoading: false,
        default: true,
        newTab: false,
        color: "#000000",
        about: ""
      },
      product: {},
      blog: {},
      productCategory: {},
      blogCategory: {},
      selectedField: {},

      //sections start
      activeSection: "",
      tempActiveSection: "",
      sectionForm:{
        label: "",
        slug: "",
        isActive: true,
        isOpen: false,
        pending: false,
      },
      updateSectionForm:{
        label: "",
        slug: "",
        record: null,
        isActive: true,
        tempKey: "",
        isOpen: false,
        pending: false,
      },
      //sections end

      //move field start
      moveFieldModal:{
        sectionsList: [],
        selectedFieldKey: "",
        selectedField: null,
        selectedFieldPreviousSection: "",
        newFieldSection: null,
        isOpen: false,
        pending: false,
      },
      //move field end

      //pages start
      pagesList:[
        {
          label: 'Home',
          slug: '/',
        },
        {
          label: 'Shop',
          slug: 'shop',
        },
        {
          label: 'Collections',
          slug: 'collections',
        },
        {
          label: 'Product Category Details',
          slug: 'collections/category-slug',
        },
        {
          label: 'Product Details',
          slug: 'product-details',
        },
        {
          label: 'Blog Category Details',
          slug: 'blog/category-slug',
        },
        {
          label: 'Blogs',
          slug: 'blog',
        },
        {
          label: 'Blog Details',
          slug: 'blog-details',
        },
        {
          label: 'About Us',
          slug: 'about-us',
        },
        {
          label: 'Contact Us',
          slug: 'contact-us',
        },
        {
          label: 'Privacy Policy',
          slug: 'privacy-policy',
        },
        {
          label: 'Terms & Conditions',
          slug: 'terms-and-conditions',
        },
        {
          label: 'FAQs',
          slug: 'faqs',
        },
        {
          label: 'Sign Up',
          slug: 'signup',
        },
        {
          label: 'Log In',
          slug: 'login',
        },
        {
          label: 'Forget Password',
          slug: 'forget-password',
        },
        {
          label: 'Reset Password',
          slug: 'forget-password/reset',
        },
        {
          label: 'Cart',
          slug: 'cart',
        },
        {
          label: 'Checkout',
          slug: 'checkout',
        },
        {
          label: 'Payment',
          slug: 'payment',
        },
        {
          label: 'Order Confirmation',
          slug: 'order-confirmation',
        },
        {
          label: 'Thank You',
          slug: 'thankyou',
        },
        {
          label: 'Profile Dashboard',
          slug: 'my-account/dashboard',
        },
        {
          label: 'Profile Page',
          slug: 'my-account/profile',
        },
        {
          label: 'Profile Wishlist',
          slug: 'my-account/wishlist',
        },
        {
          label: 'Profile Orders',
          slug: 'my-account/orders',
        },
        {
          label: 'Profile Order Details',
          slug: 'my-account/order',
        },
        {
          label: 'Search',
          slug: 'search',
        },
        {
          label: 'Header',
          slug: 'header',
        },
        {
          label: 'Footer',
          slug: 'footer',
        },
      ],
      //pages end

      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //content block steps
        {
          target: '#pills-tab',
          content: `<strong>Page Selection</strong><br>You have the option to choose a page for which you wish to update the content.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#pills-section-tab',
          content: `<strong>Sections Selection</strong><br>You have the option to choose a section for which you wish to update the content.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#content-blocks-section',
          content: `<strong>Content Block</strong><br>You have the option to modify the content of a block by utilizing the provided fields.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'top'
          }
        },
        {
          target: '#siteView',
          content: `<strong>Site Preview</strong><br>You have the ability to preview the content block that will be updated.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'top'
          }
        },
        {
          target: '#edit-theme-btn',
          content: `<strong>Edit Theme Banners</strong><br>Theme banners editing screen can be accessed by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'left'
          }
        },
        {
          target: '#save-btn',
          content: `<strong>Save Changes</strong><br>Changes can be saved by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'left'
          }
        },
      ],
      tourIsActive: false
    }
  },
  components: {
    subHeader,
    tutorialsAction,
    loaderBtn
  },
  computed: {
    ...mapGetters({
      settings: "settings_module/settings",
      loadingSettings: "settings_module/isPending",
    }),
    field(){
      
      if(this.acitveTab != "" && this.acitveTab != "0" && this.blocks[this.acitveTab] != undefined && this.blocks[this.acitveTab].name){

        return this.blocks[this.acitveTab].name;

      }else{

        return "";

      }
      
    },
    section(){

      if(this.activeSection == 'non-grouped-fields'){

        return 'Non Grouped Fields';

      }else if(this.acitveTab != "" && this.acitveTab != "0" && this.blocks[this.acitveTab] != undefined && 
      this.blocks[this.acitveTab].sections && this.blocks[this.acitveTab].sections[this.activeSection] &&
      this.blocks[this.acitveTab].sections[this.activeSection].name){

        return this.blocks[this.acitveTab].sections[this.activeSection].name;
        
      }else{

        return "";

      }

    },
    siteViewUrl(){

      if(this.settings.secondary_domain){

        let url = ''

        if(this.acitveTab){

          if(this.acitveTab == '/'){

            url = 'https://' + this.settings.secondary_domain 

          }else{

            if(this.acitveTab == 'forget-password' || this.acitveTab == 'forget-password/reset' ||
            this.acitveTab == 'thankyou' || this.acitveTab == 'search' || 
            this.acitveTab == 'order-confirmation' || this.acitveTab == 'login' || 
            this.acitveTab == 'signup' || this.acitveTab == 'payment' || 
            this.acitveTab.includes('my-account')){

              url = 'https://' + this.settings.secondary_domain + "/" + this.acitveTab + "?preview=1"

            }else if(this.acitveTab == 'cart'){

              url = 'https://' + this.settings.secondary_domain + "?cart_preview=1"

            }else if(this.acitveTab == 'blog-details'){

              if(this.blog.id){

                url = 'https://' + this.settings.secondary_domain + "/blog/" + this.blog.category.slug + "/" + this.blog.slug

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "In order to preview the blog detail page, you must first have an active blog in your store.",
                });

                url = 'https://' + this.settings.secondary_domain + "/" + this.acitveTab

              }

            }else if(this.acitveTab == 'product-details'){

              if(this.product.id){

                url = 'https://' + this.settings.secondary_domain + "/collections/" + this.product.category.slug + "/" + this.product.slug

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "In order to preview the product detail page, you must first have an active product in your store.",
                });

                url = 'https://' + this.settings.secondary_domain + "/" + this.acitveTab

              }

            }else if(this.acitveTab == 'collections/category-slug'){

              if(this.productCategory.id){

                url = 'https://' + this.settings.secondary_domain + "/collections/" + this.productCategory.slug

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "In order to preview the product category detail page, you must first have an active product category in your store.",
                });

                url = 'https://' + this.settings.secondary_domain + "/" 

              }

            }else if(this.acitveTab == 'blog/category-slug'){

              if(this.blogCategory.id){

                url = 'https://' + this.settings.secondary_domain + "/blog/" + this.blogCategory.slug

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "In order to preview the blog category detail page, you must first have an active blog category in your store.",
                });

                url = 'https://' + this.settings.secondary_domain + "/" 

              }

            }else if(this.acitveTab == 'header' || this.acitveTab == 'footer'){

              url = 'https://' + this.settings.secondary_domain + "?scroll_to=" + this.acitveTab

            }else{

              url = 'https://' + this.settings.secondary_secondary_domain + "/" + this.acitveTab

            }

          }

        }else{

          url = 'https://' + this.settings.secondary_domain 

        }

        if(this.selectedField.id){

          url = url + "#" + this.selectedField.id

        }

        return url;

      }else{

        let url = ''

        if(this.acitveTab){

          if(this.acitveTab == '/'){

            url = 'https://' + this.settings.domain 

          }else{

            if(this.acitveTab == 'forget-password' || this.acitveTab == 'forget-password/reset' ||
            this.acitveTab == 'thankyou' || this.acitveTab == 'search' || 
            this.acitveTab == 'order-confirmation' || this.acitveTab == 'login' || 
            this.acitveTab == 'signup' || this.acitveTab == 'payment' || 
            this.acitveTab.includes('my-account')){

              url = 'https://' + this.settings.domain + "/" + this.acitveTab + "?preview=1"

            }else if(this.acitveTab == 'cart'){

              url = 'https://' + this.settings.domain + "?cart_preview=1"

            }else if(this.acitveTab == 'blog-details'){

              if(this.blog.id){

                url = 'https://'+ this.settings.domain + "/blog/" + this.blog.category.slug + "/" + this.blog.slug

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "In order to preview the blog detail page, you must first have an active blog in your store.",
                });

                url = 'https://' + this.settings.domain + "/" + this.acitveTab

              }

            }else if(this.acitveTab == 'product-details'){

              if(this.product.id){

                url = 'https://' + this.settings.domain + "/collections/" + this.product.category.slug + "/" + this.product.slug

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "In order to preview the product detail page, you must first have an active product in your store.",
                });

                url = 'https://' + this.settings.domain + "/" + this.acitveTab

              }

            }else if(this.acitveTab == 'collections/category-slug'){

              if(this.productCategory.id){

                url = 'https://' + this.settings.domain + "/collections/" + this.productCategory.slug

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "In order to preview the product category detail page, you must first have an active product category in your store.",
                });

                url = 'https://' + this.settings.domain + "/" 

              }

            }else if(this.acitveTab == 'blog/category-slug'){
              
              if(this.blogCategory.id){

                url = 'https://'+this.settings.domain + "/blog/" + this.blogCategory.slug

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "In order to preview the blog category detail page, you must first have an active blog category in your store.",
                });

                url = 'https://' + this.settings.domain + "/" 

              }

            }else if(this.acitveTab == 'header' || this.acitveTab == 'footer'){

              url = 'https://' + this.settings.domain  + "?scroll_to=" + this.acitveTab

            }else{

              url = 'https://' + this.settings.domain + "/" + this.acitveTab

            }

          }

        }else{

          url = 'https://' + this.settings.domain 

        }

        if(this.selectedField.id){

          url = url + "#" + this.selectedField.id

        }

        return url;

      }

    },
  },
  watch: {
    "form.name": {
      handler: function (value){

        this.form.slug = value
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");

      },
    },
    "settings.blocks": {
      handler: function (value) {

        let obj = value ? JSON.parse(value) : {};

        if(Object.keys(obj).length > 0){

          if(this.tempActiveTab == ""){

            this.acitveTab = Object.keys(obj)[0];

          }else{

            this.acitveTab = this.tempActiveTab

          }

        }

        this.blocks = obj;

      },
      deep: true,
    },
    'acitveTab':{
      handler: function (value){

        this.selectedField = {}

        this.activeSection = 'non-grouped-fields'

      },deep:true
    },
  },
  methods: {
    selectTab(selectValue){

      if(!this.tourIsActive){

        this.acitveTab = selectValue;

      }

    },
    selectSectionTab(selectedListedSection){

      if(!this.tourIsActive){
      
        this.activeSection = selectedListedSection;

      }

    },
    blockSelection(groupKey, field){

      this.selectedField = field;

    },
    slugHandle(form){

      if(form == "add"){

        let slug = this.form.label.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");

        this.form.slug = slug;

      }else if(form == "addSection"){

        let slug = this.sectionForm.label.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");

        this.sectionForm.slug = slug;

      }else if(form == "updateSection"){

        let slug = this.updateSectionForm.label.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");

        this.updateSectionForm.slug = slug;

      }else{

        let slug = this.fieldForm.label.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");

        this.fieldForm.slug = slug;

      }

    },
    slugHandleSlugField(form){

      if(form == "add"){

        let slug = this.form.slug.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9/ -]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");

        this.form.slug = slug;

      }else if(form == "addSection"){

        let slug = this.sectionForm.slug.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9/ -]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");

        this.sectionForm.slug = slug;

      }else if(form == "updateSection"){

        let slug = this.updateSectionForm.slug.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9/ -]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");

        this.updateSectionForm.slug = slug;

      }else if(form == "addField"){

        let slug = this.fieldForm.slug.toLowerCase();
        slug = slug.replace(/[^a-zA-Z0-9/ -]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");

        this.fieldForm.slug = slug;

      }

    },
    async validate(action){

      if(action == "addPage"){

        this.$refs.addPageObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.addPageObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.addPageObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            if(this.blocks[this.form.selectedPage.slug] != undefined){

              this.$message({
                type: "error",
                showClose: true,
                message: "The selected page has already been used.",
              });

              return

            }

            let pageObj = {
              id: uuidv4(),
              name: this.form.selectedPage.label,
              sections: {},
              fields: {},
            };

            this.blocks[this.form.selectedPage.slug] = pageObj;

            this.acitveTab = this.form.selectedPage.slug;

            this.form.isOpen = false;

            this.$notify({
              type: "success",
              title: "Success",
              message:"Page has been successfully added.",
            });

            this.saveBlocks();

          }

        });

      }

      if(action == "updatePage"){

        this.$refs.editPageObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.editPageObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.editPageObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            if(this.editPageForm.tempSelectedPage){

              if(this.editPageForm.tempSelectedPage.label != this.editPageForm.selectedPage.label){

                if((this.blocks[this.editPageForm.selectedPage.slug] != undefined) && 
                this.blocks[this.editPageForm.selectedPage.slug].name == this.editPageForm.selectedPage.label){

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: "The selected page has already been used.",
                  });

                  return

                }else{

                  let tempBlocks = _.cloneDeep(this.blocks);

                  for(let key in tempBlocks){

                    if(Object.prototype.hasOwnProperty.call(tempBlocks, key)){
                      
                      if(tempBlocks[key].id == this.editPageForm.pageId){

                        if(key != this.editPageForm.selectedPage.slug){

                          let index = Object.keys(tempBlocks).findIndex(key => tempBlocks[key].id === this.editPageForm.pageId);

                          if(index !== -1){

                            let newObject = { 
                              id: tempBlocks[key].id, 
                              name: this.editPageForm.selectedPage.label,
                              fields: tempBlocks[key].fields,
                              sections: tempBlocks[key].sections
                            };

                            let newKey = this.editPageForm.selectedPage.slug;

                            let newTempBlocks = {};

                            Object.keys(tempBlocks).forEach((key, i) => {

                              if(i !== index){

                                newTempBlocks[key] = tempBlocks[key];

                              }

                              if(i === index){

                                newTempBlocks[newKey] = newObject;

                              }

                            });

                            tempBlocks = _.cloneDeep(newTempBlocks);

                          }

                        }else{

                          tempBlocks[this.editPageForm.selectedPage.slug].name = this.editPageForm.selectedPage.label;
                          
                        }

                      }

                    }

                  }

                  this.blocks = _.cloneDeep(tempBlocks);

                  this.acitveTab = this.editPageForm.selectedPage.slug;

                  this.editPageForm.isOpen = false;

                  this.$notify({
                    type: "success",
                    title: "Success",
                    message:"Page has been successfully updated.",
                  });

                  return

                }

              }

            }else{

              if((this.blocks[this.editPageForm.selectedPage.slug] != undefined) && 
              this.blocks[this.editPageForm.selectedPage.slug].name == this.editPageForm.selectedPage.label){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "The selected page has already been used.",
                });

                return

              }

              let tempBlocks = _.cloneDeep(this.blocks);

              for(let key in tempBlocks){
              
                if(Object.prototype.hasOwnProperty.call(tempBlocks, key)){
                  
                  if(tempBlocks[key].id == this.editPageForm.pageId){

                    if(key != this.editPageForm.selectedPage.slug){

                      let index = Object.keys(tempBlocks).findIndex(key => tempBlocks[key].id === this.editPageForm.pageId);

                      if(index !== -1){

                        let newObject = { 
                          id: tempBlocks[key].id, 
                          name: this.editPageForm.selectedPage.label,
                          fields: tempBlocks[key].fields,
                          sections: tempBlocks[key].sections
                        };

                        let newKey = this.editPageForm.selectedPage.slug;

                        let newTempBlocks = {};

                        Object.keys(tempBlocks).forEach((key, i) => {

                          if(i !== index){

                            newTempBlocks[key] = tempBlocks[key];

                          }

                          if(i === index){

                            newTempBlocks[newKey] = newObject;

                          }

                        });

                        tempBlocks = _.cloneDeep(newTempBlocks);

                      }

                    }else{

                      tempBlocks[this.editPageForm.selectedPage.slug].name = this.editPageForm.selectedPage.label;
                      
                    }

                  }

                }

              }
              
              this.blocks = _.cloneDeep(tempBlocks);

              this.acitveTab = this.editPageForm.selectedPage.slug;

              this.editPageForm.isOpen = false;

              this.$notify({
                type: "success",
                title: "Success",
                message:"Page has been successfully updated.",
              });

              return

            }

          }

        });

      }

      if(action == "addSection"){

        this.$refs.addSectionObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.addSectionObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.addSectionObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            if(this.blocks[this.acitveTab] == undefined || this.blocks[this.acitveTab]["sections"] == undefined){

              this.$notify({
                type: "error",
                title: "Error",
                message:"Page not found. Kindly first create page and select it.",
              });

              return;

            }

            if(this.blocks[this.acitveTab]["sections"][this.sectionForm.slug] != undefined){

              this.$notify({
                type: "error",
                title: "Error",
                message: "Slug has already been taken.",
              });

              return;

            }

            let obj = {
              id: uuidv4(),
              name: this.sectionForm.label,
              active: this.sectionForm.isActive,
              fields: {},
            }

            this.$notify({
              type: "success",
              title: "Success",
              message: "Section has been successfully added.",
            });

            this.blocks[this.acitveTab]["sections"][this.sectionForm.slug] = obj;
            
            this.saveBlocks();

            this.resetSection();

          }

        });

      }

      if(action == "updateSection"){

        this.$refs.updateSectionObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.updateSectionObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.updateSectionObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            
            if(this.updateSectionForm.record.name != this.updateSectionForm.label ||
            this.updateSectionForm.tempKey != this.updateSectionForm.slug ||
            this.updateSectionForm.record.active != this.updateSectionForm.isActive){

              if((this.blocks[this.acitveTab]['sections'][this.updateSectionForm.slug] != undefined) &&
              this.blocks[this.acitveTab]['sections'][this.updateSectionForm.slug].name == this.updateSectionForm.label &&
              this.blocks[this.acitveTab]['sections'][this.updateSectionForm.slug].id != this.updateSectionForm.record.id){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: "The selected section details have already been used.",
                });

                return

              }else{

                let tempBlocks = _.cloneDeep(this.blocks);

                for(let key in tempBlocks[this.acitveTab]['sections']){

                  if(tempBlocks[this.acitveTab]['sections'] && Object.prototype.hasOwnProperty.call(tempBlocks[this.acitveTab]['sections'], key)){
                    
                    if(tempBlocks[this.acitveTab]['sections'][key].id == this.updateSectionForm.record.id){

                      if(key != this.updateSectionForm.slug){

                        let index = Object.keys(tempBlocks[this.acitveTab]['sections']).
                        findIndex(key => tempBlocks[this.acitveTab]['sections'][key].id === this.updateSectionForm.record.id);

                        if(index !== -1){

                          let newObject = { 
                            id: tempBlocks[this.acitveTab]['sections'][key].id, 
                            name: this.updateSectionForm.label,
                            active: this.updateSectionForm.isActive,
                            fields: tempBlocks[this.acitveTab]['sections'][key].fields
                          };

                          let newKey = this.updateSectionForm.slug;

                          let newTempBlocks = {};

                          Object.keys(tempBlocks[this.acitveTab]['sections']).forEach((key, i) => {

                            if(i !== index){

                              newTempBlocks[key] = tempBlocks[this.acitveTab]['sections'][key];

                            }

                            if(i === index){

                              newTempBlocks[newKey] = newObject;

                            }

                          });

                          tempBlocks[this.acitveTab]['sections'] = _.cloneDeep(newTempBlocks);

                        }

                      }else{
                        
                        tempBlocks[this.acitveTab]['sections'][this.updateSectionForm.slug].name = this.updateSectionForm.label;

                      }

                    }
                  
                  }
                
                }

                this.blocks = _.cloneDeep(tempBlocks);

                this.updateSectionForm.isOpen = false;

                this.activeSection = this.updateSectionForm.slug;

                this.$notify({
                  type: "success",
                  title: "Success",
                  message:"Section has been successfully updated.",
                });

                return

              }

            }


          }

        });

      }

      if(action == "addField"){

        this.$refs.addFieldObserver.validate().then(async (success) => {

          if(!success){

            const errors = Object.entries(this.$refs.addFieldObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.addFieldObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            if(this.activeSection == 'non-grouped-fields'){

              if(this.blocks[this.acitveTab] == undefined || this.blocks[this.acitveTab]["fields"] == undefined){

                this.$notify({
                  type: "error",
                  title: "Error",
                  message:"Page not found. Kindly first create page and select it.",
                });

                return;

              }

              if(this.blocks[this.acitveTab]["fields"][this.fieldForm.slug] != undefined){

                this.$notify({
                  type: "error",
                  title: "Error",
                  message: "Slug has already been taken.",
                });

                return;

              }

              this.fieldForm.isLoading = true;

              let obj = {
                id: uuidv4(),
                name: this.fieldForm.label,
                type: this.fieldForm.type,
                default: true,
              };

              if(this.fieldForm.type == "image"){

                try{

                  obj["alt"] = this.fieldForm.alt;
                  obj["link"] = this.fieldForm.link;

                  let formData = new FormData();
                  formData.append("image", this.fieldForm.value);

                  let res = await this.$axios.post("/content-block/image/upload",formData);
                  if(res.data.status_code == "1280"){

                    obj["value"] = res.data.name;

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                  }
                }catch(error){

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: ex.response ? ex.response.data.message : ex.message,
                  });

                  this.fieldForm.isLoading = false;

                  return;
                  
                }

              }else if(this.fieldForm.type == "link"){

                obj["link"] = this.fieldForm.link;
                obj["value"] = this.fieldForm.value;
                obj["newTab"] = this.fieldForm.newTab;
                obj["color"] = this.fieldForm.color;

              }else if(this.fieldForm.type == "text"){

                obj["value"] = this.fieldForm.value;
                obj["color"] = this.fieldForm.color;
                obj["about"] = this.fieldForm.about;

              }

              this.$notify({
                type: "success",
                title: "Success",
                message: "Field has been successfully added.",
              });

              this.blocks[this.acitveTab]["fields"][this.fieldForm.slug] = obj;

              this.fieldForm.isOpen = false;

              this.saveBlocks();

            }else{

              if(this.blocks[this.acitveTab] == undefined || this.blocks[this.acitveTab]["sections"] == undefined){

                this.$notify({
                  type: "error",
                  title: "Error",
                  message:"Page not found. Kindly first create page and select it.",
                });

                return;

              }

              if(this.blocks[this.acitveTab].sections[this.activeSection] == undefined || 
              this.blocks[this.acitveTab]["sections"] == undefined){
                
                this.$notify({
                  type: "error",
                  title: "Error",
                  message:"Section not found. Kindly first create section and select it.",
                });

                return;
              
              }

              if(this.blocks[this.acitveTab]["sections"][this.activeSection]["fields"][this.fieldForm.slug] != undefined){

                this.$notify({
                  type: "error",
                  title: "Error",
                  message: "Slug has already been taken.",
                });

                return;

              }

              this.fieldForm.isLoading = true;

              let obj = {
                id: uuidv4(),
                name: this.fieldForm.label,
                type: this.fieldForm.type,
                default: true,
              };

              if(this.fieldForm.type == "image"){

                try{

                  obj["alt"] = this.fieldForm.alt;
                  obj["link"] = this.fieldForm.link;

                  let formData = new FormData();
                  formData.append("image", this.fieldForm.value);

                  let res = await this.$axios.post("/content-block/image/upload",formData);
                  if(res.data.status_code == "1280"){

                    obj["value"] = res.data.name;

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                  }
                }catch(error){

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: ex.response ? ex.response.data.message : ex.message,
                  });

                  return;
                  
                }finally{

                  this.fieldForm.isLoading = false;

                }

              }else if(this.fieldForm.type == "link"){

                obj["link"] = this.fieldForm.link;
                obj["value"] = this.fieldForm.value;
                obj["newTab"] = this.fieldForm.newTab;
                obj["color"] = this.fieldForm.color;

              }else if(this.fieldForm.type == "text"){

                obj["value"] = this.fieldForm.value;
                obj["color"] = this.fieldForm.color;
                obj["about"] = this.fieldForm.about;

              }
              
              this.$notify({
                type: "success",
                title: "Success",
                message: "Field has been successfully added.",
              });

              this.blocks[this.acitveTab]["sections"][this.activeSection]["fields"][this.fieldForm.slug] = obj;

              this.fieldForm.isOpen = false;

              this.saveBlocks();

            }

          }

        });

      }

      if(action == 'moveField'){

        this.$refs.moveFieldObserver.validate().then(async (success) => {

          if(!success){

            const errors = Object.entries(this.$refs.moveFieldObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.moveFieldObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            let tempBlocks = _.cloneDeep(this.blocks);

            if(this.moveFieldModal.selectedFieldPreviousSection == 'non-grouped-fields'){

              //checking if a field already exists with the same key in the new section starts here
              let isKeyFound = false;

              for(let key in tempBlocks[this.acitveTab]['sections'][this.moveFieldModal.newFieldSection.slug]['fields']){

                if(key == this.moveFieldModal.selectedFieldKey){

                  isKeyFound = true;

                }

              }
              
              if(isKeyFound){

                this.$notify({
                  type: "error",
                  title: "Error",
                  message: "A field already exists with the similar slug in the selected section.",
                });

                return

              }
              //checking if a field already exists with the same key in the new section ends here

              //adding the field to new section starts here
              tempBlocks[this.acitveTab]['sections'][this.moveFieldModal.newFieldSection.slug]['fields'][this.moveFieldModal.selectedFieldKey] = this.moveFieldModal.selectedField;
              //adding the field to new section ends here

              //removing the field from previous section starts here
              delete tempBlocks[this.acitveTab]['fields'][this.moveFieldModal.selectedFieldKey];
              //removing the field from previous section ends here

              this.blocks = _.cloneDeep(tempBlocks);

              this.$notify({
                type: "success",
                title: "Success",
                message: "Field has been successfully moved.",
              });

              this.activeSection = this.moveFieldModal.newFieldSection.slug;

              this.moveFieldModal.isOpen = false;

              this.saveBlocks();

            }else{

              if(this.moveFieldModal.newFieldSection.slug == 'non-grouped-fields'){

                //checking if a field already exists with the same key in the new section starts here
                let isKeyFound = false;

                for(let key in tempBlocks[this.acitveTab]['fields']){

                  if(key == this.moveFieldModal.selectedFieldKey){

                    isKeyFound = true;

                  }

                }

                if(isKeyFound){

                  this.$notify({
                    type: "error",
                    title: "Error",
                    message: "A field already exists with the similar slug in the selected section.",
                  });

                  return

                }
                //checking if a field already exists with the same key in the new section ends here

                //adding the field to new section starts here
                tempBlocks[this.acitveTab]['fields'][this.moveFieldModal.selectedFieldKey] = this.moveFieldModal.selectedField;
                //adding the field to new section ends here

                //removing the field from previous section starts here
                delete tempBlocks[this.acitveTab]['sections'][this.moveFieldModal.selectedFieldPreviousSection]['fields'][this.moveFieldModal.selectedFieldKey];
                //removing the field from previous section ends here

                this.blocks = _.cloneDeep(tempBlocks);

                this.$notify({
                  type: "success",
                  title: "Success",
                  message: "Field has been successfully moved.",
                });

                this.activeSection = this.moveFieldModal.newFieldSection.slug;

                this.moveFieldModal.isOpen = false;

                this.saveBlocks();

              }else{

                //checking if a field already exists with the same key in the new section starts here
                let isKeyFound = false;

                for(let key in tempBlocks[this.acitveTab]['sections'][this.moveFieldModal.newFieldSection.slug]['fields']){

                  if(key == this.moveFieldModal.selectedFieldKey){

                    isKeyFound = true;

                  }

                }

                if(isKeyFound){

                  this.$notify({
                    type: "error",
                    title: "Error",
                    message: "A field already exists with the similar slug in the selected section.",
                  });

                  return

                }
                //checking if a field already exists with the same key in the new section ends here

                //adding the field to new section starts here
                tempBlocks[this.acitveTab]['sections'][this.moveFieldModal.newFieldSection.slug]['fields'][this.moveFieldModal.selectedFieldKey] = this.moveFieldModal.selectedField;
                //adding the field to new section ends here

                //removing the field from previous section starts here
                delete tempBlocks[this.acitveTab]['sections'][this.moveFieldModal.selectedFieldPreviousSection]['fields'][this.moveFieldModal.selectedFieldKey];
                //removing the field from previous section ends here

                this.blocks = _.cloneDeep(tempBlocks);

                this.$notify({
                  type: "success",
                  title: "Success",
                  message: "Field has been successfully moved.",
                });

                this.activeSection = this.moveFieldModal.newFieldSection.slug;

                this.moveFieldModal.isOpen = false;

                this.saveBlocks();

              }

            }

          }

        });

      }

    },
    openNewPageModal(){

      this.form = {
        selectedPage: null,
        label: "",
        slug: "",
        isOpen: true,
      };

      this.$refs.addPageObserver.reset();

    },
    resetForm(){

      this.form = {
        selectedPage: null,
        label: "",
        slug: "",
        isOpen: false,
      };

      this.$refs.addPageObserver.reset();

      this.fieldForm = {
        isOpen: false,
        label: "",
        slug: "",
        type: "",
        value: "",
        isLoading: false,
        color: "#000000",
        about: ""
      };

      this.$refs.addFieldObserver.reset();

    },
    filterBlock(){

      let inp = this.searchedItem;

      this.blocksList = this.tempBlocksList.filter((block) =>

        block.label.toLowerCase().includes(inp.toLowerCase())

      );

    },
    async handleImageAddField(e){
      
      const {valid} = await this.$refs.imageProvider.validate(e);
      
      if(valid){
      
        const {files} = e.target;
        this.fieldForm.value = files[0];

      }else{

        this.$refs.fieldImageUploader.value = '';

      }

    },
    async updateImageHandler(e, group, field, value){

      if(e.target.files.length > 0){
      
        const file = e.target.files[0];

        if(file.name.length > 50){

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file name must not exceed 50 characters.",
          }); 

          return

        }

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
        
        }else if(file.type.includes('image/')){

          let uploadedFileType = file.type.slice(6);
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

          return

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

          return

        }

        let loader = Loading.service({
          text: "Please wait we are uploading image.",
          fullscreen: true,
        });

        let formData = new FormData();
        formData.append("previous_image_name", value);
        formData.append("new_image", file);

        try{
          let res = await this.$axios.post("/content-block/image/update",formData);
          if(res.data.status_code == "1281"){

            if(this.activeSection == 'non-grouped-fields'){

              let clone = _.cloneDeep(this.blocks);

              clone[group]["fields"][field]["value"] = res.data.name;
              clone[group]["fields"][field]["default"] = false;
              this.blocks = clone;

            }else{

              let tempBlocks = _.cloneDeep(this.blocks);

              tempBlocks[this.acitveTab]['sections'][group]['fields'][field]['value'] = res.data.name
              tempBlocks[this.acitveTab]['sections'][group]['fields'][field]['default'] = false;

              this.blocks = tempBlocks;

            }

            this.saveBlocks();

            this.$store.commit('gallery_module/CLEAR_MODULE');

            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          }

        }catch(error){

          this.$message({
            type: "error",
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          loader.close();

        }

      }

    },
    async saveBlocks(){

      let loader = Loading.service({
        text: "Saving blocks. Please wait!",
        fullscreen: true,
      });

      // let tempSrc = this.$refs.siteView.src

      this.$refs.siteView.src = '';

      this.tempActiveTab = this.acitveTab;

      this.acitveTab = "";

      try{
        let res = await this.$axios.post("/store-settings/content-blocks/update", {blocks: JSON.stringify(this.blocks)});
        if(res.data.status_code == "1177"){

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          this.$store.commit("settings_module/update_blocks", res.data.blocks);

          this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          this.$store.commit('gallery_module/CLEAR_MODULE');

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        loader.close();

        this.acitveTab = this.tempActiveTab

        // this.$refs.siteView.src = tempSrc

      }

    },
    handleDeleteField(group, field){

      if(!this.tourIsActive){

        MessageBox.confirm(
          "Are you sure you want to remove the field?", 
          "Warning", 
          {
            type: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          }
        ).then(async ()=>{

          if(this.activeSection == 'non-grouped-fields'){

            let tempBlocks = _.cloneDeep(this.blocks);

            let tempField = tempBlocks[this.acitveTab]["fields"][field];

            if(tempField.type == 'image'){

              let formData = new FormData();

              let blockImages = [];

              let tempImage = {
                name:tempField.value
              }

              blockImages.push(tempImage);

              formData.append('images',JSON.stringify(blockImages))

              let loader = Loading.service({
                text: "Field is being removed. Please wait!",
                fullscreen: true,
              });

              try{
                let res = await this.$axios.post('/content-block/images/remove',formData);
                if(res.data.status_code == "1284"){

                  loader.close();

                  let blocks = _.cloneDeep(this.blocks);

                  delete blocks[group]["fields"][field];

                  this.blocks = blocks;
                  
                  this.tempActiveTab = ""

                  this.saveBlocks();

                }
              }catch(error){

                loader.close();

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response ? error.response.data.message : error.message,
                });

              }

            }else{

              let blocks = _.cloneDeep(this.blocks);

              delete blocks[group]["fields"][field];

              this.blocks = blocks;

              this.tempActiveTab = ""

              this.saveBlocks();

            }

          }else{

            let tempBlocks = _.cloneDeep(this.blocks);

            let tempField = tempBlocks[this.acitveTab]['sections'][this.activeSection]["fields"][field];

            if(tempField.type == 'image'){

              let formData = new FormData();

              let blockImages = [];

              let tempImage = {
                name:tempField.value
              }

              blockImages.push(tempImage);

              formData.append('images',JSON.stringify(blockImages))

              let loader = Loading.service({
                text: "Field is being removed. Please wait!",
                fullscreen: true,
              });

              try{
                let res = await this.$axios.post('/content-block/images/remove',formData);
                if(res.data.status_code == "1284"){

                  loader.close();

                  let blocks = _.cloneDeep(this.blocks);

                  delete blocks[this.acitveTab]['sections'][this.activeSection]["fields"][field];

                  this.blocks = blocks;
                  
                  this.tempActiveTab = ""

                  this.saveBlocks();

                }
              }catch(error){

                loader.close();

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response ? error.response.data.message : error.message,
                });

              }

            }else{

              let blocks = _.cloneDeep(this.blocks);

              delete blocks[this.acitveTab]['sections'][this.activeSection]["fields"][field];

              this.blocks = blocks;

              this.tempActiveTab = ""

              this.saveBlocks();

            }

          }

        }).catch(()=>{});

      }

    },

    //edit/delete page options start
    openEditPageModal(group){

      let tempBlocks = _.cloneDeep(this.blocks);

      let selectedKey = '';

      for(let key in tempBlocks){
      
        if(Object.prototype.hasOwnProperty.call(tempBlocks, key)){

          if(tempBlocks[key].id == group.id){
  
            selectedKey = key;

          }
  
        }
      
      }

      let tempListedSelectedPage = null;

      if(selectedKey){

        this.pagesList.forEach((page) => {

          if(page.slug == selectedKey && page.label == tempBlocks[selectedKey].name){

            tempListedSelectedPage = page;

          }

        });

      }

      this.editPageForm = {
        selectedPage: tempListedSelectedPage,
        tempSelectedPage: tempListedSelectedPage,
        pageId:group.id,
        isOpen: true,
      }

    },
    async removePageHandle(group){

      MessageBox.confirm(
        "Are you sure you want to remove the page? All the associated fields will also be removed.", 
        "Warning", 
        {
          type: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }
      ).then(async () => {

        let loader = Loading.service({
          text: "Page is being removed. Please wait!",
          fullscreen: true,
        });

        let blocks = _.cloneDeep(this.blocks);

        let fieldImagesList = [];

        for(let key in blocks){

          if(Object.prototype.hasOwnProperty.call(blocks, key)){

            if(JSON.stringify(blocks[key]) === JSON.stringify(group)){
              
              let blockFields = _.cloneDeep(blocks[key]['fields'])

              for(let fieldKey in blockFields){

                if(Object.prototype.hasOwnProperty.call(blockFields, fieldKey)){

                  if(blockFields[fieldKey].type == 'image'){

                    let tempImageField = {
                      name: blockFields[fieldKey].value
                    }

                    fieldImagesList.push(tempImageField);

                  }

                }

              }

            }

          }

        }

        if(fieldImagesList.length > 0){

          let formData = new FormData();

          formData.append('images',JSON.stringify(fieldImagesList))

          try{
            let res = await this.$axios.post('/content-block/images/remove',formData);
            if(res.data.status_code == "1284"){

              let blocks = _.cloneDeep(this.blocks);

              for(let key in blocks){

                if(Object.prototype.hasOwnProperty.call(blocks, key)){

                  if(JSON.stringify(blocks[key]) === JSON.stringify(group)){

                    delete blocks[key];
                    break; 

                  }

                }

              }

              this.blocks = blocks;

              if(Object.keys(this.blocks).length > 0){

                this.acitveTab = Object.keys(this.blocks)[0];

              }

              loader.close();

              this.$notify({
                type: "success",
                title: "Success",
                message: "Page has been successfully removed.",
              });

              this.saveBlocks();

            }
          }catch(error){

            loader.close();

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }

        }else{

          let blocks = _.cloneDeep(this.blocks);

          for(let key in blocks){

            if(Object.prototype.hasOwnProperty.call(blocks, key)){

              if(JSON.stringify(blocks[key]) === JSON.stringify(group)){

                delete blocks[key];
                break; 

              }

            }

          }

          this.blocks = blocks;

          if(Object.keys(this.blocks).length > 0){

            this.acitveTab = Object.keys(this.blocks)[0];

          }

          this.$notify({
            type: "success",
            title: "Success",
            message: "Page has been successfully removed.",
          });

          loader.close();

          this.saveBlocks();

        }

      }).catch(() => {})

    },
    //edit/delete page options end

    //set images to default start
    setImageToDefaultHandle(){

      MessageBox.confirm(
        "Do you really want to set all the image fields to default?", 
        "Warning", 
        {
          type: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }
      ).then(async () => {

        let tempBlocks = _.cloneDeep(this.blocks);

        for(let key in tempBlocks){
        
          if(Object.prototype.hasOwnProperty.call(tempBlocks, key)){
          
            let blockFields = _.cloneDeep(tempBlocks[key]['fields']);

            for(let fieldKey in blockFields){

              if(Object.prototype.hasOwnProperty.call(blockFields, fieldKey)){

                if(blockFields[fieldKey].type == 'image' && 
                !blockFields[fieldKey].default){
                  
                  blockFields[fieldKey].default = true;

                }

              }

            }

            tempBlocks[key]['fields'] = blockFields;

            //section fields
            let blockSections =  _.cloneDeep(tempBlocks[key]['sections']);

            for(let sectionKey in blockSections){

              if(Object.prototype.hasOwnProperty.call(blockSections, sectionKey)){
                
                let sectionFields = _.cloneDeep(blockSections[sectionKey]['fields']);

                for(let sectionFieldKey in sectionFields){

                  if(sectionFields[sectionFieldKey].type == 'image' &&
                  !sectionFields[sectionFieldKey].default){

                    sectionFields[sectionFieldKey].default = true;

                  }

                }
                
                blockSections[sectionKey]['fields'] = sectionFields

              }

            }

            tempBlocks[key]['sections'] = blockSections;

          }
        
        }

        this.blocks = _.cloneDeep(tempBlocks);

        this.$notify({
          type: "success",
          title: "Success",
          message: "All the image fields have been successfully set to default.",
        });

      }).catch(() => {})

    },
    //set images to default end

    //download image from image block start
    imageDownloadHandle(imgTagId,field,e){

      var imageUrl = document.getElementById(imgTagId).src;

      var a = document.createElement('a');
      a.href = imageUrl;
      a.download = field.value;
      a.target = '_blank'

      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);

    },
    //download image from image block end

    //download images of all image blocks start (backend)
    async downloadBlockImagesHandle(){

      this.$prompt('Email Address*', 'Download Block Images', 
      {
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: 'A valid email address is required',
        beforeClose: async (action, instance, done) => {

          if(action === 'confirm'){

            this.$notify({
              type: "success",
              title: "Success",
              message: "Download process initiated. You'll receive an email having all the images in a zip file soon.",
            });

            done();

            let formData = new FormData();

            formData.append('email',instance._data.inputValue);
            formData.append('blocks_type','content');

            // instance.confirmButtonText = 'Loading...';
            // instance.confirmButtonLoading = true;

            try{
              let res = await this.$axios.post('/content-block/images/download',formData);
              if(res.data.status_code == "4001"){

                this.$notify({
                  type: "success",
                  title: "Success",
                  message: res.data.message,
                });

              }
            }catch(error){

              if(error.response){

                if(error.response.data.error.includes('failed') ||
                error.response.data.error.includes('associated') ||
                error.response.data.error.includes('unsuccessful')){

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response.data.error,
                  });

                }else{

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response.data.message,
                  });

                }

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.message,
                });

              }

            }finally{

              // instance.confirmButtonText = 'Continue';

              // instance.confirmButtonLoading = false;

              // done();

            }

          }else{

            done();

          }

        }
      }).then(({ value }) => {

      }).catch(() => { });

    },
    //download images of all image blocks end

    //sections methods start here
    resetSection(){

      this.sectionForm = {
        label: "",
        slug: "",
        isActive: true,
        isOpen: false,
        pending: false,
      }

      this.$refs.addSectionObserver.reset();

      this.updateSectionForm = {
        label: "",
        slug: "",
        record: null,
        isActive: true,
        isOpen: false,
        pending: false,
      }

      this.$refs.updateSectionObserver.reset();

    },
    openEditSectionModal(section){

      let tempBlocks = _.cloneDeep(this.blocks);

      let selectedKey = '';

      for(let key in tempBlocks[this.acitveTab]['sections']){

        if(Object.prototype.hasOwnProperty.call(tempBlocks[this.acitveTab]['sections'], key)){

          if(tempBlocks[this.acitveTab]['sections'][key].id == section.id){

            selectedKey = key;

          }

        }

      }

      this.updateSectionForm = {
        label: section.name,
        slug: selectedKey,
        isActive: section.active,
        record: section,
        tempKey: selectedKey,
        isOpen: true,
        pending: false,
      }

    },
    removeSectionModal(section){

      MessageBox.confirm(
        "Are you sure you want to remove the section? All the associated fields will also be removed.", 
        "Warning", 
        {
          type: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }
      ).then(async () => {

        let loader = Loading.service({
          text: "Section is being removed. Please wait!",
          fullscreen: true,
        });

        let blocks = _.cloneDeep(this.blocks[this.acitveTab]['sections']);

        let fieldImagesList = [];

        for(let key in blocks){

          if(Object.prototype.hasOwnProperty.call(blocks, key)){

            if(JSON.stringify(blocks[key]) === JSON.stringify(section)){

              let blockFields = _.cloneDeep(blocks[key]['fields'])

              for(let fieldKey in blockFields){

                if(Object.prototype.hasOwnProperty.call(blockFields, fieldKey)){

                  if(blockFields[fieldKey].type == 'image'){

                    let tempImageField = {
                      name: blockFields[fieldKey].value
                    }

                    fieldImagesList.push(tempImageField);

                  }

                }

              }

            }

          }

        }

        if(fieldImagesList.length > 0){

          let formData = new FormData();

          formData.append('images',JSON.stringify(fieldImagesList))

          try{
            let res = await this.$axios.post('/content-block/images/remove',formData);
            if(res.data.status_code == "1284"){

              let blocks = _.cloneDeep(this.blocks[this.acitveTab]['sections']);

              for(let key in blocks){

                if(Object.prototype.hasOwnProperty.call(blocks, key)){

                  if(JSON.stringify(blocks[key]) === JSON.stringify(section)){

                    delete blocks[key];
                    break; 

                  }

                }

              }

              this.blocks[this.acitveTab]['sections'] = blocks;

              this.activeSection = 'non-grouped-fields'

              this.$notify({
                type: "success",
                title: "Success",
                message: "Section has been successfully removed.",
              });

              loader.close();

              this.saveBlocks();

            }
          }catch(error){

            loader.close();

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }

        }else{

          let blocks = _.cloneDeep(this.blocks[this.acitveTab]['sections']);

          for(let key in blocks){

            if(Object.prototype.hasOwnProperty.call(blocks, key)){

              if(JSON.stringify(blocks[key]) === JSON.stringify(section)){

                delete blocks[key];
                break; 

              }

            }

          }

          this.blocks[this.acitveTab]['sections'] = blocks;

          this.activeSection = 'non-grouped-fields'

          this.$notify({
            type: "success",
            title: "Success",
            message: "Section has been successfully removed.",
          });

          loader.close();

          this.saveBlocks();

        }

      }).catch(() => {})

    },
    hideShowSectionHandle(sectionKey, section){

      MessageBox.confirm(
        this.blocks[this.acitveTab]['sections'][sectionKey].active ? "Are you sure you want to make the section hidden?."
        : "Are you sure you want to make the section visible?.", 
        "Warning", 
        {
          type: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }
      ).then(async () => {

        this.blocks[this.acitveTab]['sections'][sectionKey].active = !this.blocks[this.acitveTab]['sections'][sectionKey].active;

        this.saveBlocks();

      }).catch(() => {})

    },
    //sections methods end here

    //move field methods start here
    moveFieldModalOperations(action, fieldKey, field){

      if(action == 'open'){

        let tempSectionsList = [];

        if(this.activeSection != 'non-grouped-fields'){

          let nonGroupedFields = {
            name: 'Non Grouped Fields',
            slug: 'non-grouped-fields',
          }

          tempSectionsList.push(nonGroupedFields);

        }

        for(let key in this.blocks[this.acitveTab]['sections']){
          
          if(key != this.activeSection){

            let tempSectionItem = {
              name: this.blocks[this.acitveTab]['sections'][key].name,
              slug: key,
            }

            tempSectionsList.push(tempSectionItem);

          }

        }

        if(tempSectionsList.length == 0){

          this.$notify({
            type: "error",
            title: "Error",
            message:"No sections found in the selected page.",
          });

          return

        }

        this.moveFieldModal = {
          sectionsList: tempSectionsList,
          selectedFieldKey: fieldKey,
          selectedField: field,
          selectedFieldPreviousSection: this.activeSection,
          newFieldSection: null,
          isOpen: true,
          pending: false,
        }

        this.$refs.moveFieldObserver.reset();

      }else if(action == 'close'){

      }

    },
    //move field methods end here

    //tour methods
    startContentBlocksTour(){

      this.$tours.contentBlocksTour.start();

      this.tourIsActive = true;

    },
    handleNextStep(currentStep){

    },
    handlePreviousStep(currentStep){

    },
    handleTourStop(){

      this.tourIsActive = false;

    },
    handleTourFinish(){

      this.tourIsActive = false;

    },
    handleTourSkip(){

      this.tourIsActive = false;

    },

    //environment check
    isDev(){

      if(process.env.VUE_APP_ENV == 'dev'){

        return true

      }else{

        return false

      }

    },

    //image loading error handling
    handleImageError(e){

      e.target.src = '/images/image_not_found.png'

    },

  },
  mounted(){  

  },
  async beforeMount(){
    
    let obj = this.settings.blocks ? JSON.parse(this.settings.blocks) : {};

    if(Object.keys(obj).length > 0){

      if(this.tempActiveTab == ""){

        this.acitveTab = Object.keys(obj)[0];

      }else{

        this.acitveTab = this.tempActiveTab;

      }

    }
    
    this.blocks = obj;

    let res = await this.$axios.get('/getProducts',{params:{offset:1,status:'active'}})
    if(res.data.status_code == "1007"){

      if(res.data.products.data.length > 0){

        this.product = res.data.products.data[0];

      }

    }
    
    let blogRes = await this.$axios.get('/getBlogs',{params:{offset:1,active:1}});
    if(blogRes.data.status_code == "1038"){

      if(blogRes.data.blogs.data.length > 0){

        this.blog = blogRes.data.blogs.data[0];

      }

    }

    let productCategoryRes = await this.$axios.get('/getCategories',{params:{offset:1,active:1}});
    if(productCategoryRes.data.status_code == "1003"){

      if(productCategoryRes.data.categories.data.length > 0){

        this.productCategory = productCategoryRes.data.categories.data[0];

      }

    }

    let blogCategoryRes = await this.$axios.get('/blog-category/all',{params:{offset:1,active:1}});
    if(blogCategoryRes.data.status_code == "1042"){

      if(blogCategoryRes.data.categories.data.length > 0){

        this.blogCategory = blogCategoryRes.data.categories.data[0];

      }

    }

  },
  beforeDestroy(){
  },
};
</script>
<style scoped>

.tabsListSection{
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.tabsListSection ul{
  flex:1;
  list-style: none;
  border-right: 1px solid #eee;
  box-sizing: border-box;
  background-color: white;
}
.tabsListSection ul li{
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.tabsListSection ul li button{
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #303133;
  position: relative;
}
.tabsListSection .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  background-color: white;
  border-radius: 0;
  color: #1ec2c2;
}
.tabsListSection p {
  text-align: center;
  background-color: #fff;
  width: 100%;
}
.tabsListSection.sectionItems{
  margin-top: 5px;
}

.tabsContentSection{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
.tabsContentSection .tabsContent{
  flex: 1;
  width: 50%;
  max-height: 910px;
  overflow: auto;
  margin: 30px 0;
  box-sizing: border-box;
}
.tabsContentSection .tabPageSection{
  flex: 1;
  width: 50%;
  padding: 30px;
}

.tabsContentSection .tabPageSection .siteViewBox iframe{
  width: 100%;
  transform: scale(1);
  max-height: 910px;
}
.tabsContentSection .tabsContent .tabMainBox{
  column-gap: 0;
  row-gap: 30px;
  justify-content: space-between;
}
.tabsContentSection .tabsContent .tabCardBox{
  width:48%;
}


.viewOrderBox tbody tr td {
  cursor: inherit !important;
}
.category-tab-img {
  height: 400px;
  width: 400px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.category-tab-img:hover {
  border-color: #409eff;
}
.category-tab-img img {
  height: 100%;
  width: 100%;
}
.category-tab-img svg {
  position: absolute;
  width: 50px;
  top: 43%;
  left: 0;
  right: 0;
  margin: 0 auto;
  fill: #d9d9d9;
}
.category-tab-img:hover svg {
  fill: #409eff;
}
.custom-form.create-section .ql-editor {
  color: #000 !important;
}
.ql-editor {
  height: 200px;
}
.el-message {
  z-index: 99999 !important;
}
.tabCardBox {
  width: 31.9%;
  background-color: #fff;
  padding: 35px 20px;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 3px 15px 0px rgb(0 0 0 / 16%);
  position: relative;
  border-radius: 4px;
}
.ctmCloseBtn {
  background-color: transparent;
  padding: 0px;
  border: none;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 3px;
  right: 8px;
}
.ctmCloseBtn svg {
  width: 100%;
  height: 100%;
}
.tabMainBox {
  padding: 30px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
}
.fieldBox {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.fieldBox:nth-last-child(1) {
  margin-bottom: 0px;
}
.fieldBox label {
  font-size: 14px;
  color: #adadad;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 5px;
}
.fieldBox input {
  width: 82%;
  border-radius: 4px;
  background: #ededed;
  border: none;
  padding: 8px 11px;
  font-size: 14px;
}
.fieldBox input[type="color"] {
  height: 30px;
  padding: 0px !important;
  width: 60px;
  border: none;
  margin-right: auto;
  margin-left: 19px;
}
.fieldImage {
  width: 100%;
  height: 175px;
  border: 1px dashed #c7c7c7;
  border-radius: 0px;
  margin-top: 0px;
  position: relative;
  background-color: #ededed
}
.fieldImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.uploaderNewImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  background: #1ec2c2;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploaderNewImg input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}
.uploaderNewImg svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}
.downloaderNewImg{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  background: #1ec2c2;
  position: absolute;
  top: 80%;
  right: 8px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.downloaderNewImg svg{
  width: 100%;
  height: 100%;
  fill: #fff;
}
.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-left: 1px solid #eee;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #eee;
  border-bottom: none;
  border-radius: 0;
}
.el-tabs--card > .el-tabs__header {
  border-bottom: 1px solid #eee;
}
.el-tabs__item:hover {
  color: #303133;
}
.el-tabs__item.is-active {
  color: #1ec2c2;
  background: transparent;
  /* border-bottom: 1px solid #14223d !important; */
}
.el-tabs__nav-wrap {
  margin-bottom: 0px;
}
.fieldBox input:disabled {
  opacity: 0.7;
}
/* .tabCardBox .fieldBox:nth-child(2) input:disabled{
      opacity: 1;
      color: #000;
      font-weight: 500;
    } */
.tabCardBox .fieldBox:nth-last-child(1) input {
  font-weight: 500;
}
.tabCardHeader {
  background-color: #e6e6e6;
  padding: 8px 2px 8px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabCardHeader h5 {
  font-size: 16px;
  color: #14223d;
  font-weight: 400;
  margin-bottom: 0px;
}
.cardBodyBlock {
  padding: 21px;
}
.fieldBox textarea {
  border: 1px solid #cccccc;
  border-radius: 0px;
  min-height: 175px;
  max-height: 175px;
  width: 100%;
  padding: 11px 13px;
  font-weight: 400;
  color: #000;
}
.blockIconBox {
  width: 30px;
  height: 30px;
  display: inline-block;
  /* background: #fff; */
  margin-left: 0px;
  border-radius: 50%;
  padding: 3px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.tabCardHeader h5:nth-last-child(1) {
  display: flex;
  align-items: center;
  margin-right: 0px;
}
.tabCardHeader h5 span svg {
  width: 100%;
  height: 100%;
  fill: #1ec2c2;
}
.viewImgIcon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1ec2c2;
  position: absolute;
  top: 42px;
  right: 8px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.viewImgIcon svg {
  widows: 100%;
  height: 100%;
  fill: #fff;
}

@media (max-width: 1024px) {
  .tabCardBox {
    width: 48.2%;
  }

}
@media (max-width: 991px) {
  .tabMainBox {
    padding: 20px;
    gap: 24px;
  }
  .addNewOrderBtn button {
    padding: 8px 14px;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .tabsContentSection .tabPageSection{
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .tabMainBox {
    padding: 15px;
    gap: 24px;
  }

  .tabCardBox {
    width: 100%;
  }
  .tabsContentSection{
    flex-direction: column;
  }
  .tabsContentSection .tabsContent{
    width: 100%;
  }
  .tabsContentSection .tabPageSection{
    width: 100%;
    padding: 15px;
  }
  .tabsContentSection .tabsContent .tabCardBox{
    width: 48%!important;
  }
}
@media (max-width: 576px) {
  .tabsContentSection .tabsContent .tabCardBox{
    width: 100%!important;
  }
  
}

.formContentBox input[type="checkbox"] {
  width: auto !important;
  margin-left: 5px;
}
.formContentBox input[type="color"] {
  width: 30px !important;
  margin-left: 5px;
  padding: 0 !important;
  height: 30px;
}
.main-search-order {
  margin-top: 0px;
}
.addNewOrderBtn button {
  background-color: #15223d;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  padding: 7px 33px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  margin-bottom: 5px;
}
.addNewOrderBtn button:hover {
  background-color: #fd4d5d;
  transition: all 0.3s ease-in-out;
}
.addNewOrderBtn button svg {
  width: 14px;
  height: 14px;
  position: relative;
  top: -1px;
  margin-right: 5px;
  display: inline-block;
}
.addNewOrderBtn {
  text-align: right;
}
.viewOrderBox {
  overflow: hidden;
  box-shadow: 1px 1px 11px #e8e8e8;
  margin-top: 25px;
  margin-bottom: 25px;
  background: #fff;
  padding: 0px;
  position: relative;
}
.el-message-box .el-input input{
  padding-left: 10px !important
}
.el-message-box .el-message-box__input{
  padding-top: 5px !important;
}
</style>
